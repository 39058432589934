body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.LazyLoad{
    margin: 2px;
    display: block;
    cursor: pointer;
}

@keyframes fadeInImg {
 from{
   opacity: 0
 }
 to {
   opacity: 1
 }
}
.img-loading{
   opacity: 0;
   width: 100%;
   height: auto;
}
.img-loaded{
   animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
   position: relative;
   opacity: 0;
   animation-fill-mode: forwards;
   animation-duration: 0.7s;
   animation-delay: 0.1s;
}